.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--master);
  transform-origin: 0%;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Light.woff2") format("woff2"),
    url("./fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Unna";
  src: url("./fonts/Unna-Regular.woff2") format("woff2"),
    url("./fonts/Unna-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --font-color: #ffffff;
    --bg-color: #000000;
    --master: #a60f32;
    --master_two: #55555a;
  }
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --font-color: #000000;
    --bg-color: #fff;
    --master: #a60f32;
    --master_two: #55555a;
  }
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  font-optical-sizing: auto;
  color: var(--font-color);
  line-height: 1.5;
  font-size: 18px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.5s, background-color 0.5s;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Unna";
  font-weight: normal;
  font-style: normal;
  line-height: 1.2;
}

a {
  color: var(--font-color);
}

a:hover {
  color: var(--font-color);
  opacity: 0.7;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

a,
button,
input,
textarea {
  font-family: inherit;
}
a,
button {
  cursor: pointer;
  line-height: 1;
}
*:focus {
  outline: 0 !important;
}
img,
video,
iframe {
  max-inline-size: 100%;
  block-size: auto;
  display: flex;
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside;
  margin-left: 15px;
}
.max-width {
  max-width: 1366px;
  margin: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
}
.padding {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.center {
  text-align: center;
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1180px) {
}

@media only screen and (max-width: 867px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 441px) {
}

@media screen and (prefers-color-scheme: dark) {
}

@media screen and (prefers-color-scheme: light) {
}

@media (horizontal-viewport-segments: 2) and (vertical-viewport-segments: 1) {
}

@media (vertical-viewport-segments: 2) and (horizontal-viewport-segments: 1) {
}
