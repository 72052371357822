header.App-header {
  position: absolute;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
img.site-logo {
  width: 300px;
  height: 80px;
  object-fit: contain;
}
.lang-holder a {
  font-size: 18px;
  margin-right: 10px;
  font-weight: 800;
  color: #000000;
}
a.site-link-active {
  color: #a60f32;
}
.lang-holder li.active a {
  color: #a60f32;
}
.lang-holder li {
  list-style: none;
}
.lang-holder {
  display: flex;
}
@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1180px) {
  header.App-header {
    position: relative;
    background: white;
  }
}

@media only screen and (max-width: 867px) {
}

@media only screen and (max-width: 667px) {
  img.site-logo {
    margin-bottom: 20px;
  }
  header.App-header {
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 60px;
    padding-left: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .lang-holder a {
    font-size: 22px;
    margin-right: 20px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 441px) {
}

@media screen and (prefers-color-scheme: dark) {
}

@media screen and (prefers-color-scheme: light) {
}

@media (horizontal-viewport-segments: 2) and (vertical-viewport-segments: 1) {
}

@media (vertical-viewport-segments: 2) and (horizontal-viewport-segments: 1) {
}
